import React from "react";
import "./Footer.css";
import { Grid, Header, Segment, Container, List } from "semantic-ui-react";

export default function footer() {
  return (
    <Segment inverted vertical style={{ padding: "5em 0em" }}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.00, maximum-scale=2.00, minimum-scale=1.00"
      ></meta>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={5}>
              <Header
                inverted
                style={{ textAlign: "center" }}
                as="h4"
                content="Sitemap"
              />
              <List link inverted style={{ textAlign: "center" }}>
                <List.Item as="a" onClick="/">
                <a href="/">
                  Home
                </a>
                </List.Item>
                <List.Item as="a" onClick="/donate">
                <a href="/donate">
                  Donate
                </a>
                </List.Item>
                <List.Item as="a" onClick="/about">
                <a href="/about">
                  About
                </a>
                </List.Item>
              </List>
            </Grid.Column>

            <Grid.Column width={5}>
              <Header
                inverted
                style={{ textAlign: "center" }}
                as="h4"
                content="Address"
              />
              <List link inverted style={{ textAlign: "center" }}>
                <List.Item as="a">
                  BHISMA Dept., ISKCON, Hare Krishna Land, Juhu, Mumbai 400049
                  INDIA
                </List.Item>
              </List>
            </Grid.Column>

            <Grid.Column width={5}>
              <Header
                inverted
                style={{ textAlign: "center" }}
                as="h4"
                content="Contact"
              />
              <List link inverted style={{ textAlign: "center" }}>
                <List.Item as="a">
                  Contact Person : Dr. Mukunda Madhava Das, Ph.D. (Vice
                  President ISKCON Juhu)
                </List.Item>
                <List.Item as="a">
                  {" "}
                  Call us : +91 99877 86448 / +91 74000 56919
                </List.Item>
                <List.Item as="a">Write To us at : mmd@iskconjuhu.in</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}
