import React from 'react';

export default function SuccessPage () {
  return (
    <div>
    <br></br>
    <br></br>
    <br></br>
      <h2>Donation Success</h2>
      <p>Thank you for your Donation! Your transaction was successful.</p>
    </div>
  );
};