import React, { useState, useEffect } from "react";
import { Button, Grid, Checkbox, Input, Label, Modal } from "semantic-ui-react";
import "./Donate.css";
import axios from "axios";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";

export default function Kalash () {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [amount, setAmount] = useState(0);
  const [othergauamount, setOtherGauAmount] = useState(0);
  const [otherannaamount, setOtherAnnaAmount] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [responseHtml, setResponseHtml] = useState("");
  const generateHash = (params, salt) => {
    const sortedParams = Object.keys(params)
      .sort()
      .map((key) => `${key}=${params[key]}`)
      .join("|");

    const hashString = `${sortedParams}|${salt}`;
    // const hash = crypto.createHash('sha512').update(hashString).digest('hex');
    const hash = Base64.stringify(hmacSHA512(hashString, salt));
    return hash;
  };

  useEffect(() => {
    setTotalAmount(
      parseInt(amount) + parseInt(othergauamount) + parseInt(otherannaamount)
    );
  }, [amount, othergauamount, otherannaamount]);

  const handleCheck = async (event, data) => {
    if (data.checked) {
      setAmount(parseInt(amount) + parseInt(data.value));
    } else {
      setAmount(parseInt(amount) - parseInt(data.value));
    }
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      const TxnId =
        "ISKCON-JUHU-" +
        Date.now().toString() +
        Math.random().toString(36).substring(2, 15);

      const params = {
        key: "2fKjPt",
        txnid: TxnId,
        amount: totalAmount,
        productinfo: "KalashDonation",
        firstname: name,
        email: email,
        phone: mobile,
      };

      /* const response = await axios.post("https://secure.payu.in/_payment", {
        key: "2fKjPt",
        txnid: TxnId,
        amount: totalAmount,
        productinfo: "Donation",
        firstname: name,
        email: email,
        phone: mobile,
        surl: "https://iskconjuhu.in/success.html",
        furl: "https://iskconjuhu.in/failure.html",
        hash: generateHash(params, "zBqitHlab9VU52l9ZDv8x5D1rxYBtgat"),
        pg: "UPI",
      }); */

    /*   const response = await axios.post("https://iskconjuhu.in/donate.php", {
        key: "2fKjPt",
        txnid: TxnId,
        amount: totalAmount,
        productinfo: "Donation",
        firstname: name,
        email: email,
        phone: mobile,
        surl: "https://iskconjuhu.in/success.html",
        furl: "https://iskconjuhu.in/failure.html",
        salt: "zBqitHlab9VU52l9ZDv8x5D1rxYBtgat",
        pg: "UPI",
      });
 */
       const response = await axios.get("https://iskconjuhu.in/donate.php", {
        params: {
          key: "2fKjPt",
          txnid: TxnId,
          amount: totalAmount,
          productinfo: "Donation",
          firstname: name,
          email: email,
          phone: mobile,
          surl: "https://iskconjuhu.in/success.html",
          furl: "https://iskconjuhu.in/failure.html",
          salt: "zBqitHlab9VU52l9ZDv8x5D1rxYBtgat",
          pg: "UPI",
        },
      });

      const htmlContent = response.data;
      const newWindow = window.open();
      newWindow.document.open();
      newWindow.document.write(htmlContent);
      newWindow.document.close();
      window.close();
      setResponseHtml("");
      setOpen(true);
      console.log(response);
    } catch (error) {
      console.error("Error occurred during payment:", error);
      setPaymentStatus("failed");
      setOpen(false);
    }
  };
  return (
    <>
      {responseHtml ? (
        <div dangerouslySetInnerHTML={{ __html: responseHtml }} />
      ) : (
        <div className="donatediv">
          <img className="donateimg" src="kalash.png"></img>
          <h1>Kalash Abhishek</h1>
          <br />
          <h5>
            Bathing Lord Shri Krishna on Krishna Janmashtami cleanses all bad
            influences in one's life Get a Chance to Bathe the Lord on His
            Birthday -Sponsor a Kalash for the Maha-Abhishek of the Lord
          </h5>
          <br />
          <h5>Donation for Kalash Maha-Abhishek</h5>
          <Grid columns={1} divided>
            <Grid.Row>
              <Grid.Column>
                <Checkbox
                  value="180000"
                  label="₹ 5 Kalash 180,000"
                  onChange={handleCheck}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  value="135000"
                  label="₹ 3 Kalash 135,000 "
                  onChange={handleCheck}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  value="90000"
                  label="₹ 2 Kalash 90,000"
                  onChange={handleCheck}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  value="45000"
                  label="₹ 1 Kalash 45,000"
                  onChange={handleCheck}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <br />
          <Modal
            centered={false}
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={<Button primary>Donate Now</Button>}
          >
            <Modal.Header>Donation Details</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                Total Amount : {totalAmount}
              </Modal.Description>
              <h5>Donor Details</h5>
              <Grid columns={3} divided>
                <Grid.Row>
                  <Grid.Column>
                    <Input
                      placeholder="Donor Name..."
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      type="email"
                      placeholder="Donor Email..."
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      type="number"
                      placeholder="Donor Mobile..."
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <div className="donatenowbtn">
                <Button primary onClick={handlePayment}>
                  Pay Now
                </Button>
                {paymentStatus === "success" && <h5>Donation successful!</h5>}
                {paymentStatus === "failed" && (
                  <h5>Donation failed. Please try again later.</h5>
                )}
              </div>
            </Modal.Actions>
          </Modal>
        </div>
      )}
    </>
  );
};
