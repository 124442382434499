import React from 'react'
import { Button,Grid, Header, Segment,Image,Container,Divider } from 'semantic-ui-react'

export default function About() {
  return (
    <div>
      <Divider
          as="h4"
          className="header"
          horizontal
          style={{ margin: "3em 0em", textTransform: "uppercase" }}
        >
        <a>ISKCON Juhu</a>
        </Divider>
    <Segment style={{ padding: "8em 0em" }} vertical>
    <Grid container stackable verticalAlign="middle">
        <Grid.Row>
        <Grid.Column width={8}>
        {/* <Divider
          as="h4"
          className="header"
          horizontal
          style={{ margin: "3em 0em", textTransform: "uppercase" }}
        >
        <a href="#root">Case Studies</a>
        </Divider> */}

          <Header as="h3" style={{ fontSize: "2em" }}>
          ISKCON Juhu
          </Header>
          <p style={{ fontSize: "1.33em" }}>
            
Hare Krishna Land (ISKCON Juhu) is set upon four acres of prime land and a stone throw away from Juhu beach. A.C. Bhaktivedanta Swami Prabhupada, founder of ISKCON, inspired the construction of one of India’s most beautiful temple of Lord Krishna in Mumbai.

The presiding Deities at the temple are Sri Sri Gaura-Nitai, Sri Sri Radha Rasabihari and Sri Sri Sita Rama Laxman Hanuman.The Deities were personally installed by His Divine Grace A. C. Bhaktivedanta Swami Prabhupada.

          </p>
        </Grid.Column>
        <Grid.Column floated="right" width={6}>
          <Image
            size="small"
            src="logo.png"
          />
        </Grid.Column>
      </Grid.Row>
  
  </Grid>
</Segment>
 </div>

  
  )
}