import React from 'react';

export default function FailurePage () {
  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <h2>Donation Failure</h2>
      <p>Oops! Your transaction could not be processed. Please try again later.</p>
    </div>
  );
};
