import React, { useState } from "react";
import { Button, Segment } from "semantic-ui-react";
import "./Donate.css";
import { useNavigate } from "react-router-dom";

const DonateButton = () => {
  const navigate = useNavigate();
  return (
    /*     <div className='donate'>
      <Button className='donatebutton' onClick={()=>navigate("/donate")}>
        Donate Now
      </Button>
    </div> */
    <div>
      <a
        href="https://api.whatsapp.com/send?phone=916263756519&text=Hare%20Krishna%2C%20%20I%20need%20some%20information%20about%20Iskcon%20Juhu"
        class="float"
        target="_blank"
      >
        <i class="fa fa-whatsapp my-float"></i>
      </a>
    </div>
  );
};
export default DonateButton;
