import React from "react";
import "./App.css";
import Home from "./Pages/home";
import { Routes, Route } from "react-router-dom";
import Org from "./Components/Auth/Org";
import People from "./Components/Auth/People";
import useform, { validate } from "./Components/function/formhandle";
import Olog from "./Components/Login/Olog";
import OSignup from "./Components/Signup/OSignup";
import Login from "./Components/Login/Login";
import Signup from "./Components/Signup/Signup";
import Forgot from "./Components/Forgot/Forgot";
import Main from "./Pages/Main";
import SuccessPage from "./Pages/successPage";
import FailurePage from "./Pages/failurePage";
import DonateNow from "./Components/Donate/DonateNow";
import AnnaDaan from "./Components/Donate/AnnaDaan";
import GauSeva from "./Components/Donate/GauSeva";
import Kalash from "./Components/Donate/Kalash";
import About from "./Components/About/About";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="org" element={<Org />}>
        <Route
          path="login"
          element={<Olog form={useform()} validate={validate} />}
        />
        <Route
          path="signup"
          element={<OSignup form={useform()} validate={validate} />}
        />
      </Route>
      <Route path="people" element={<People />}>
        <Route
          path="login"
          element={<Login form={useform()} validate={validate} />}
        />
        <Route
          path="signup"
          element={<Signup form={useform()} validate={validate} />}
        />
      </Route>
      <Route
        path="forgot"
        element={<Forgot form={useform()} validate={validate} />}
      ></Route>
      <Route path="main" element={<Main />} />
      <Route path="/success" element={<SuccessPage></SuccessPage>} />
      <Route path="/failure" element={<FailurePage></FailurePage>} />
      <Route path="/gau-seva" element={<GauSeva></GauSeva>} />
      <Route path="/donate" element={<DonateNow></DonateNow>} />
      <Route path="/anna-daan" element={<AnnaDaan></AnnaDaan>} />
      <Route path="/kalash" element={<Kalash></Kalash>} />
      <Route path="/about" element={<About></About>} />
    </Routes>
  );
}

export default App;
