import React, { useEffect, useState,useRef } from 'react';
import "./Banner.css";
import { useNavigate} from "react-router-dom";

const images = ["Banner1.png","Banner2.png","Banner3.png","Banner4.png"]
const delay = 3500;
function Banner() {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

    useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  const navigate = useNavigate();

    return(
        <header className="banner"
         style={{
             backgroundImage: `url(${images[index]})`,
             transition: `all 0.8s ease-in-out`
         }}>
            {/* <div className="banner__contents">
                <h1 style={{ fontSize : 30}} className="banner__title">
                ISKCON Juhu
                </h1>
            <h1 className="banner__description">
            Janmashtami Anna Daan Seva
            </h1>
            </div> */}
            {/* <div className="banner--fadeBottom"/> */}
        </header>
  
    );
}

export default Banner