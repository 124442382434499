import React from 'react'
import Sidebar from '../Components/main/Sidebar/Sidebar'

export default function Main() {
  return (
      <div>
          <Sidebar/>
      </div>    
  )
}
