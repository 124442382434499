import {Icon} from "semantic-ui-react";
import React , {useRef} from "react";
import "./NavBar.css";
import { useLocation } from 'react-router-dom';

export default function Navbar() {
    const navbar = useRef(null);
    const sidebar = useRef(null);
    let i=0,f=0;
    const show = () =>{
        if(i===0)
        {
            navbar.current.style.display = "flex";
            i=1;
            f=1;
        }
        else{
            navbar.current.style.display = "none";
            i=0;
            f=0;
        }
    }

    const click = () => {
        if(f===1)
        {
            navbar.current.style.display = "none";
            f=0;
            i=0;
        }
    }

    const location  = useLocation();


  return (
      <header className="top">
          <div id="brand" className="brand">
            <img src="./logo.png" alt="Logo" />
            ISKCON Juhu
          </div>
          <div className="temp">
            <nav className="navbar" id="navbar" ref={navbar}>
             <a  href="/" onClick={click}>Home</a>
             <a  href="/donate" onClick={click}>Donate</a>
             <a href="/about" onClick={click}>About</a>
            </nav>
            <div className="sidebar" id="sidebar" ref={sidebar} onClick={show}>
                 <Icon name="bars" size='big'/>
            </div>
            </div>
      </header>
  );
}